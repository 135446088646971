/*#################################################
### TEN PLIK ZAWIERA WSZYSTKIE WYKORZYSTYWANE   ###
### W OBRĘBIE APLIKACJI FORMATTERY, BEZ WZGLĘDU ###
### NA ICH POCHODZENIE I ZASTOSOWANIE           ###
#################################################*/

import moment from "./moment";

import { Appointment } from "@/types/appointment";
import { Chat } from "@/types/chat";
import { ProductAvailabilitySlot } from "@/types/product";
// nie zastosowano aliasu, ponieważ Jest ma problem z importem enuma
import { Currency } from "../types/general";
import {
    UserPublicDataFragment,
    UserTherapistJobTitle,
    UserTherapistJobTitleLabel
} from "@/types/user";
import {
    UserDiscount,
    UserDiscountSource,
    UserDiscountAffiliateParty,
    UserDiscountAffiliateCampaign
} from "@/types/user-discount";

/*##################
### APPOINTMENTS ###
##################*/
type AppointmentTileDateOpts = {
    only_time?: boolean;
    timezone?: string;
};

export function formatAppointmentTileDate(
    appointment: Appointment,
    opts: AppointmentTileDateOpts = { only_time: false, timezone: "Europe/Warsaw" }
): string {
    const AD = moment(appointment.start_time).tz(opts.timezone);

    if (opts.only_time) {
        return AD.format("HH:mm");
    } else if (AD.isSame(moment().tz(opts.timezone), "day")) {
        return `Dziś, ${AD.format("HH:mm")}`;
    } else {
        return AD.format("dd, DD MMM YYYY HH:mm");
    }
}

export function formatProductSlotTimeRange(
    slot: ProductAvailabilitySlot,
    timezone: string = "Europe/Warsaw"
) {
    return (
        moment(slot.from).tz(timezone).format("HH:mm") +
        " - " +
        moment(slot.to).tz(timezone).format("HH:mm")
    );
}

export function formatProductSlotDate(
    slot: ProductAvailabilitySlot,
    timezone: string = "Europe/Warsaw"
) {
    return moment(slot.from).tz(timezone).format("dd, D MMM");
}

/*#############
### STRINGS ###
#############*/
export function capitalizeString(str: string) {
    return str
        .split(" ")
        .map(it => it.slice(0, 1).toLocaleUpperCase() + it.slice(1).toLocaleLowerCase())
        .join(" ");
}

export function nl2br(str: string, is_Xhtml: boolean) {
    const break_tag = is_Xhtml || typeof is_Xhtml === "undefined" ? "<br " + "/>" : "<br>";
    return (str + "").replace(/(\r\n|\n\r|\r|\n)/g, break_tag + "$1");
}

/*#############
### NUMBERS ###
#############*/

/**
 * Metoda formatuje przekazaną liczbę według przekazanych parametrów (zwraca ją jako string)
 * @param {number | string} number Liczba do sformatowania
 * @param {number} [decimals=0] Liczba miejsc po przecinku
 * @param {string} [decimal_separator=,] Separator części całkowitej i ułamkowej
 * @param {string} [thousands_separator= ] Separator tysięcy
 * @return {String} Sformatowana liczba
 */
export function formatNumber(
    num: number | string,
    decimals = 0,
    decimal_separator = ",",
    thousands_separator = " "
) {
    if (typeof num !== "number" && isNaN(parseFloat(num))) {
        return "Cannot format non-numeric value";
    }

    if (typeof num === "string") num = parseFloat(num);

    let rs = `${num < 0 ? "-" : ""}`;
    const INT_VALUE = Math.abs(Math.trunc(num));

    // 1. Część całkowita liczby
    let intvstr = INT_VALUE.toString();
    const INT_GROUPS = [];
    while (intvstr.length > 0) {
        INT_GROUPS.unshift(intvstr.slice(-3));
        intvstr = intvstr.slice(0, -3);
    }
    rs += INT_GROUPS.join(thousands_separator);

    // 2. Część ułamkowa (opcjonalna)
    if (decimals > 0) {
        let decstr: string[] | string = num.toString().split(".");
        if (decstr.length === 2) {
            decstr = decstr[1];
        } else {
            decstr = "";
        }

        // Musimy zaokrąglić
        if (decstr.length > decimals) {
            let decnum = parseInt(decstr);
            while (Math.trunc(decnum).toString().length > decimals) {
                decnum /= 10;
            }
            decstr = Math.round(decnum).toString();
        }
        // Musimy dopełnić
        else if (decstr.length < decimals) {
            while (decstr.length < decimals) {
                decstr += "0";
            }
        }

        rs += decimal_separator + decstr;
    }

    return rs;
}

/**
 * Metoda służy do formatowania ceny
 * @param {number} price Cena (w groszach) do wyświetlenia
 * @param {Currency} [currency=Currency.PLN] Waluta w jakiej jest cena
 * @returns {string} Sformatowana cena
 */
export function formatPrice(price: number, currency: Currency = Currency.PLN) {
    const CURRENCY_TEMPLATES = Object.freeze({
        [Currency.PLN]: {
            sl: "{price} zł",
            decimals: 2,
            decimal_separator: ",",
            thousands_separator: " "
        },
        [Currency.EUR]: {
            sl: "€{price}",
            decimals: 2,
            decimal_separator: ",",
            thousands_separator: " "
        }
    });

    return CURRENCY_TEMPLATES[currency].sl.replace(
        "{price}",
        formatNumber(
            price / 100,
            CURRENCY_TEMPLATES[currency].decimals,
            CURRENCY_TEMPLATES[currency].decimal_separator,
            CURRENCY_TEMPLATES[currency].thousands_separator
        )
    );
}

/**
 * Metoda służy do formatowania rozmiaru pliku
 * @param {number} size Rozmiar (w bajtach)
 * @returns {string} Sformatowany rozmiar
 */
export function formatFileSize(size: number): string {
    const sizes = ["B", "KB", "MB", "GB", "TB"];

    if (size === 0) return "0 B";

    const i = Math.floor(Math.log(size) / Math.log(1024));

    return Math.round(100 * (size / Math.pow(1024, i))) / 100 + " " + sizes[i];
}

export function formatRate(rate: number, decimals = 0): string {
    if (rate > 500) {
        return formatNumber(5);
    } else if (rate < 100) {
        return formatNumber(1);
    } else {
        return formatNumber(rate / 100, decimals);
    }
}

/*######################
### TIME & DURATIONS ###
######################*/
/**
 * Metoda pozwala na wyświetlenie w sposób przyjazny dla człowieka czasu trwania wyrażonego w minutach. Metoda obsługuje tylko wyświetlanie w formacie godziny-minuty.
 *
 * @param {number} minutes Czas trwania w minutach
 * @returns {string}
 */
export function formatMinutesAsHumanizedDuration(minutes: number): string {
    if (minutes < 0) {
        throw new Error("Negative duration input!");
    }

    const H = Math.floor(minutes / 60);
    const M = minutes % 60;

    if (H === 0) {
        return `${M} min`;
    } else if (M === 0) {
        return `${H} g`;
    } else {
        return `${H} g ${M} min`;
    }
}
export function formatSecondsAsHumanizedDuration(seconds: number): string {
    if (seconds < 0) {
        throw new Error("Negative duration input!");
    }

    const minutes = Math.floor(seconds / 60);
    const remaining_seconds = seconds % 60;

    if (minutes === 0) {
        return `${remaining_seconds} sek`;
    } else if (remaining_seconds === 0) {
        return `${minutes} min`;
    } else {
        return `${minutes} min ${remaining_seconds} sek`;
    }
}

/*#################
### DATE & TIME ###
################ */
const DAY_SHORT_NAMES = ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "Sb"];
const DAY_NAMES = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];
const MONTH_SHORT_NAMES = [
    "Sty",
    "Lu",
    "Mar",
    "Kwi",
    "Maj",
    "Cze",
    "Lip",
    "Sie",
    "Wrz",
    "Paź",
    "Lis",
    "Gru"
];
const MONTH_NAMES = [
    "Stycznia",
    "Lutego",
    "Marca",
    "Kwietnia",
    "Maja",
    "Czerwca",
    "Lipca",
    "Sierpnia",
    "Września",
    "Października",
    "Listopada",
    "Grudnia"
];

/**
 * Metoda formatująca podaną datę według przekazanego szablonu
 *
 * Dostępne elementy składowe w szablonie:
 * ##### Dzień:
 * - `d` - dzień miesiąca w postaci dwucyfrowej (z opcjonalnym dopełnieniem zerami)
 * - `D` - skrócona, dwuliterowa nazwa dnia tygodnia (Pn-Nd)
 * - `j` - dzień miesiąca bez dopełnienia zerami
 * - `l (małe 'L')` - pełna nazwa dnia tygodnia
 * - `N` - numer dnia tygodnia (Poniedziałek = 1, Niedziela = 7)
 * - `S` - angielski suffix porządkowy generowany na podstawie dnia miesiąca (`st`, `nd`, `rd`, `th`)
 * - `w` - numeryczna reprezentacja dnia tygodnia (0-based, Niedziela = 0, Sobota = 6)
 * ##### Miesiąc:
 * - `F` - pełna, tekstowa nazwa miesiąca
 * - `m` - numer miesiąca z dopełnieniem zerami (od `01` do `12`)
 * - `M` - skrócona, tekstowa nazwa miesiąca
 * - `n` - numer miesiąca bez dopełniania zerami (od `1` do `12`)
 * ##### Rok
 * - `Y` - numeryczna reprezentacja roku (4 cyfry)
 * - `y` - dwie ostatnie cyfry roku
 * ##### Czas
 * - `a` - Ante meridiem / Post meridiem pisane małymi literami (`am` lub `pm`)
 * - `A` - Ante meridiem / Post meridiem pisane wielkimi literami (`AM` lub `PM`)
 * - `g` - godzina w 12-godzinnym formacie bez dopełnienia zerami (od `1` do `12`)
 * - `G` - godzina w 24-godzinnym formacie bez dopełnienia zerami (od `0` do `23`)
 * - `h` - godzina w 12-godzinnym formacie z dopełnieniem zerami (od `01` do `12`)
 * - `H` - godzina w 24-godzinnym formacie z dopełnieniem zerami (od `00` do `23`)
 * - `i` - minuty z dopełnieniem zerami (od `00` do `59`)
 * - `s` - sekundy z dopełnieniem zerami (od `00` do `59`)
 *
 * @param {number|Date} [timestamp=Date.now()] - Data wejściowa w formie timestamp
 * @param {string} [format=d.m.Y] - Szablon formatowania daty
 * @returns {string}
 *
 *
 */
export function formatDate(date: number | Date = Date.now(), format: string = "d.m.Y") {
    const D = new Date(date);

    const D_DAY = D.getDay();
    const D_DATE = D.getDate();
    const D_MONTH = D.getMonth();
    const D_YEAR = D.getFullYear();
    const D_HOURS = D.getHours();
    const D_MINUTES = D.getMinutes();
    const D_SECONDS = D.getSeconds();

    function englishSuffixResolver(input: number) {
        if (input === 1) return "st";
        if (input === 2) return "nd";
        if (input === 3) return "rd";

        const MOD_REST = input % 100;
        if (MOD_REST > 20) {
            const MOD_REST2 = MOD_REST % 10;
            if (MOD_REST2 === 1) return "st";
            if (MOD_REST2 === 2) return "nd";
            if (MOD_REST2 === 3) return "rd";
        }

        return "th";
    }

    const AMPM = D_HOURS < 12 ? "am" : "pm";
    const H12 = D_HOURS === 0 ? 12 : D_HOURS > 12 ? D_HOURS - 12 : D_HOURS;

    const REPLACE_DICTIONARY: Record<string, string> = {
        // Dzień
        d: D_DATE.toString().padStart(2, "0"),
        D: DAY_SHORT_NAMES[D_DAY],
        j: D_DATE.toString(),
        l: DAY_NAMES[D_DAY],
        N: (D_DAY === 0 ? 7 : D_DAY).toString(),
        S: englishSuffixResolver(D_DATE),
        w: D_DAY.toString(),

        // Miesiąc
        F: MONTH_NAMES[D_MONTH],
        m: (D_MONTH + 1).toString().padStart(2, "0"),
        M: MONTH_SHORT_NAMES[D_MONTH],
        n: (D_MONTH + 1).toString(),

        // Rok
        Y: D_YEAR.toString(),
        y: D_YEAR.toString().slice(-2),

        // Czas
        a: AMPM,
        A: AMPM.toUpperCase(),
        g: H12.toString(),
        G: D_HOURS.toString(),
        h: H12.toString().padStart(2, "0"),
        H: D_HOURS.toString().padStart(2, "0"),
        i: D_MINUTES.toString().padStart(2, "0"),
        s: D_SECONDS.toString().padStart(2, "0")
    };

    return format.replace(/[dDjlNSwFmMnYyaAgGhHis]/g, function (match) {
        return REPLACE_DICTIONARY[match];
    });
}

type Options = {
    hide_time?: boolean;
    timezone?: string;
};

export const displayChatMessageDate = (d: number, opts: Options = {}): string => {
    const timezone = opts.timezone || "UTC";
    const m = moment(d).tz(timezone);
    const now = moment().tz(timezone);

    // 1.Jeśli data jest w przyszłości, zwróć "Teraz"
    if (now.diff(m, "minutes") < 3 && now.isSame(m, "day")) {
        return "Teraz";
    }

    // 2. Jeśli różnica jest mniejsza niż 24h i to jest ten sam dzień, zwróć tylko czas
    if (now.diff(m, "hours") < 24 && now.isSame(m, "day")) {
        return m.format("HH:mm");
    }

    // 3. Jeśli różnica jest mniejsza niż 24h i to jest wczoraj, zwróć "Wczoraj" + czas
    const YESTERDAY_NOW = now.clone();
    YESTERDAY_NOW.subtract(1, "day");
    if (YESTERDAY_NOW.isSame(m, "day") && now.diff(m, "hours") < 24) {
        return `Wczoraj ${m.format("HH:mm")}`;
    }

    // 4. Jeśli różnica jest mniejsza niż 7 dni, zwróć dzień tygodnia + czas
    if (now.diff(m, "weeks") === 0) {
        return capitalizeString(m.format("dddd, HH:mm"));
    }

    // 5. Jeśli różnica jest większa niż 7 dni, zwróć datę + czas
    if (opts.hide_time === true) {
        return m.format("DD.MM.YYYY");
    }

    // 6. W przeciwnym wypadku zwróć pełną datę
    return m.format("DD.MM.YYYY HH:mm");
};

/*###########
### USERS ###
########## */
export function populateChatUser(user_id: string, chat: Chat): UserPublicDataFragment | undefined {
    if (chat === undefined) return;
    const user = chat.users.find(u => u._id === user_id);

    if (user) {
        return user;
    }

    const kicked_user = chat.kicked_users.find(u => u._id === user_id);

    if (kicked_user) {
        return kicked_user;
    }

    return undefined;
}

export function formatUserTherapistJobTitles(job_titles: UserTherapistJobTitle[]) {
    return job_titles.map(it => UserTherapistJobTitleLabel[it]).join(", ");
}

/*####################
### USER DISCOUNTS ###
####################*/
export function formatUserDiscountName(discount: UserDiscount) {
    let name = "";

    if (discount.source === UserDiscountSource.CUSTOM) {
        name = discount.custom.name;
    } else if (discount.source === UserDiscountSource.AFFILIATE) {
        if (discount.affiliate.campaign === UserDiscountAffiliateCampaign.AF_4X15_1) {
            name += "15 zł rabatu na 4 najbliższe wizyty - nagroda za ";
            if (discount.affiliate.party === UserDiscountAffiliateParty.RECOMMENDER) {
                name += "polecenie Risify ";
            } else {
                name += "dołączenie do Risify z polecenia znajomego ";
            }
            name += `(${(
                discount.affiliate.ref.first_name +
                " " +
                discount.affiliate.ref.last_name
            ).trim()})`;
        }
    } else if (discount.source === UserDiscountSource.SELFDEV_PROGRAM_CONTRACT) {
        name = `Zakup programu rozwojowego sfinansowany przez firmę ${discount.sdp_contract.ref.company_name}`;
    }

    return name;
}

/*###############
### REPLACERS ###
############## */
export function replaceTags(subject: string, replaces: Record<string, string>) {
    let s = subject;

    const RV = Object.entries(replaces);
    for (let i = 0; i < RV.length; i++) {
        s = s.replace(new RegExp("\\{\\s*" + RV[i][0] + "\\s*\\}", "g"), RV[i][1]);
    }

    return s;
}

/*############
### COLORS ###
########### */
export function getRelativeLuminance(color: string) {
    const match = color.match(/\w\w/g);

    if (!match) {
        return 0;
    }

    const [r, g, b] = match.map((hex: string) => parseInt(hex, 16) / 255);

    const linearize = (v: number) =>
        v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);

    const r_lin = linearize(r);
    const g_lin = linearize(g);
    const b_lin = linearize(b);

    return 0.2126 * r_lin + 0.7152 * g_lin + 0.0722 * b_lin;
}
