import { Plugin, createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import { createHead } from "@unhead/vue";
import { createNotivue } from "notivue";
import { App as CapacitorApp, URLOpenListenerEvent } from "@capacitor/app";
import * as Sentry from "@sentry/vue";
// import * as SentryCapacitor from "@sentry/capacitor";
import { Router } from "vue-router";
import { Capacitor } from "@capacitor/core";

import VueApexCharts from "vue3-apexcharts";
import TextClamp from "vue3-text-clamp";
//@ts-ignore
import vClickOutside from "click-outside-vue3";
import "@stripe/stripe-js";

import App from "./App.vue";
import router from "./plugins/router";

import "./styles/index.scss";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "notivue/animations.css";
import "@vuepic/vue-datepicker/dist/main.css";
import { i18n } from "./plugins/i18n";

const VUE_APP = createApp(App);

declare module "pinia" {
    export interface PiniaCustomProperties {
        router: Router;
    }
}
const pinia = createPinia();
pinia.use(({ store }) => {
    store.router = markRaw(router);
});

CapacitorApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
    const slug = event.url.split(".pl").pop();
    if (slug) {
        router.push({
            path: slug
        });
    }
});

const head = createHead();

VUE_APP.use(router);
VUE_APP.use(i18n);
VUE_APP.use(head);
VUE_APP.use(pinia);
VUE_APP.use(VueApexCharts as Plugin);
VUE_APP.use(TextClamp);
VUE_APP.use(vClickOutside);

export const displayAlert = createNotivue(VUE_APP, {
    position: "top-center",
    limit: 1,
    enqueue: true,
    notifications: {
        global: {
            duration: 5000
        }
    }
}); // To musi być przed .mount()!

// SENTRY-WEB
if (
    import.meta.env.DEV === false &&
    import.meta.env.MODE === "production" &&
    !Capacitor.isNativePlatform()
) {
    Sentry.init({
        app: VUE_APP,
        dsn: import.meta.env.VITE_SENTRY_WEB_DSN,
        integrations: [Sentry.replayIntegration()],
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 1,
        release: "risify-app@" + import.meta.env.VITE_NPM_PACKAGE_VERSION || "unknown",
        environment: import.meta.env.MODE,
        debug: false
    });
}
// else if (
//     Capacitor.isNativePlatform() &&
//     import.meta.env.DEV === false &&
//     import.meta.env.MODE === "production"
// ) {
//     SentryCapacitor.init(
//         {
//             app: VUE_APP,
//             dsn: import.meta.env.VITE_SENTRY_NATIVE_DSN,
//             integrations: [Sentry.replayIntegration()],
//             replaysSessionSampleRate: 0.01,
//             replaysOnErrorSampleRate: 1,
//             release:
//                 `risify-app-${Capacitor.getPlatform()}@` + Capacitor.getPlatform() === "ios"
//                     ? import.meta.env.VITE_IOS_APP_VERSION
//                     : import.meta.env.VITE_ANDROID_APP_VERSION,
//             environment: import.meta.env.MODE,
//             debug: false
//         },
//         Sentry.init
//     );
// }

VUE_APP.mount("#app");
