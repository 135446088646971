import { computed } from "vue";
import { useBreakpoints } from "./breakpoints";
import { useRoute } from "vue-router";

const ALWAYS_HIDDEN_BOTTOM_NAVIGATION_ROUTE_NAMES = [
    "welcome",
    "quiz",
    "quiz-board",
    "offline",
    "e404",
    "affprog-invite",
    "auth-signin",
    "auth-signup",
    "auth-join-appointment",
    "wiz-fn1-service-and-date",
    "wiz-fn1-service-and-date-preselected",
    "wiz-fn1-appointment-reschedule",
    "wiz-fn1-onboarding",
    "wiz-fn2-onboarding",
    "wiz-fn1-cscheckout",
    "wiz-fn1-first-appointment-date",
    "wiz-fn1-pay-appointment",
    "documents-privacy",
    "documents-terms",
    "knowledge-blog-post",
    "help-center-helpdesk-thread",
    "therapist-recruitment",
    "recruitments",
    "vc",
    "user-invite",
    "redirect",
    "redirect-kidme",
    "redirect-push-notification",
    "survey-preview",
    "vc2-test",
    "vc2-twilio-2",
    "therapist-video-testimonials",
    "lp-experimental-pbc30d",
    "selfdev-programs",
    "selfdev-programs-participation",
    "selfdev-programs-participation-onboarding",
    "selfdev-programs-fn1-about",
    "selfdev-programs-fn1-choose-program"
];
const MOBILE_HIDDEN_BOTTOM_NAVIGATION_ROUTE_NAMES = [
    "my-account",
    "my-account-general-data",
    "my-account-login-data-and-security",
    "my-account-notifications",
    "my-account-payments",
    "my-account-recommend-friends",
    "my-account-recommend-friends-sent",
    "my-account-settings",
    "my-account-experience",
    "my-account-education",
    "my-account-products",
    "my-account-availability",
    "my-account-linked-users",
    "chat",
    "vc"
];

export function useDefaultLayout() {
    const route = useRoute();
    const { is_mobile } = useBreakpoints();

    const is_bottom_navigation_visible = computed(
        () =>
            !ALWAYS_HIDDEN_BOTTOM_NAVIGATION_ROUTE_NAMES.includes(route.name as string) &&
            !(
                is_mobile.value &&
                MOBILE_HIDDEN_BOTTOM_NAVIGATION_ROUTE_NAMES.includes(route.name as string)
            )
    );

    return { is_bottom_navigation_visible };
}

export function getMaxZIndex(element?: HTMLElement) {
    let children_array = element
        ? element.querySelectorAll("*")
        : document.querySelectorAll("body *");

    return Math.max(
        ...Array.from(children_array, el => parseFloat(window.getComputedStyle(el).zIndex)).filter(
            zIndex => !Number.isNaN(zIndex)
        ),
        0
    );
}
