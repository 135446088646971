import { displayAlert } from "@/main";
import {
    ActionPerformed,
    PushNotifications,
    PushNotificationSchema
} from "@capacitor/push-notifications";
import { App } from "@capacitor/app";
import { Capacitor, PermissionState, PluginListenerHandle } from "@capacitor/core";
import { ref, onMounted, onUnmounted } from "vue";
import { httpJsonRequest } from "@/plugins/fetchApi";
import { ApiPushSubscriptionResponse } from "@/types/user";
import { useAuthStore } from "@/stores/auth";
import { PushSubscriptionProvider } from "@/types/user-device";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { waitForMs } from "./waiters";
import { PushNotificationName, PushNotificationRisifyCustomData } from "@/types/push-notifications";
import router from "@/plugins/router";
import { emitter } from "@/plugins/eventEmitter";

type RegistrationResult = {
    success: boolean;
};

enum PushNotificationsChannel {
    APPOINTMENTS = "appointments",
    DEFAULT = "default"
}

export function handlePushNotificationReceived(notification: PushNotificationSchema) {
    if (Capacitor.getPlatform() === "ios" && !notification.data.rispn) return;

    const NOTIFICATION: PushNotificationRisifyCustomData = notification.data.rispn
        ? notification.data.rispn
        : notification.data;

    if (NOTIFICATION.name === PushNotificationName.NEXT_APPOINTMENT_SCHEDULE_REMINDER) {
        emitter.emit("DefaultLayout::showPushNotificationDialog", notification);
    }
}

export function handlePushNotificationActionPerformed(action: ActionPerformed) {
    if (Capacitor.getPlatform() === "ios" && !action.notification.data.rispn) return;

    const NOTIFICATION: PushNotificationRisifyCustomData = action.notification.data.rispn
        ? action.notification.data.rispn
        : action.notification.data;
    router.push({
        name: "redirect-push-notification",
        params: {
            id: NOTIFICATION.last_completed_appointment_id
        }
    });
}

export function usePushNotifications() {
    if (!Capacitor.isNativePlatform()) {
        console.warn("Push notifications are only available in native apps.");

        return {
            register: async () => ({ success: false }),
            unregister: async () => ({ success: false }),
            permission_state: ref<PermissionState>("denied"),
            checkPermissions: async () => "denied",
            openNativeNotificationsSettings: async () => {}
        };
    }

    /*###########
    ### HOOKS ###
    ########## */
    const auth_store = useAuthStore();
    const permission_state = ref<PermissionState>();
    const CAPACITOR_EVENT_LISTENERS_HANDLES: PluginListenerHandle[] = [];

    /*#################
    ### PERMISSIONS ###
    ################ */
    async function checkPermissions() {
        permission_state.value = (await PushNotifications.checkPermissions()).receive;
        return permission_state.value;
    }

    /*###########################
    ### REGISTER & UNREGISTER ###
    ########################## */
    async function unregister(): Promise<{ success: boolean; error?: any }> {
        if (!auth_store.user_device) return { success: false, error: "No user device" };

        try {
            await PushNotifications.unregister();

            const r = await httpJsonRequest<ApiPushSubscriptionResponse>(
                `/user-devices/${auth_store.user_device._id}/push-subscription`,
                { method: "DELETE" },
                { supress_errors: true }
            );

            auth_store.user_device = r.user_device;

            displayAlert.info({
                message: "Nie będziesz otrzymywać więcej powiadomień PUSH"
            });

            return { success: true };
        } catch (err) {
            console.error(err);

            return { success: false, error: err };
        }
    }

    async function register(): Promise<RegistrationResult> {
        const status = await checkPermissions();

        if (status === "denied") {
            displayAlert.info({
                message: "Powiadomienia są zablokowane. Możesz je włączyć w ustawieniach telefonu.",
                title: "Brak uprawnień"
            });

            return { success: false };
        }

        const REGISTRATION_RESULT: { completed: boolean; token: string | null } = {
            completed: false,
            token: null
        };
        const REGISTRATION_EVENT_HANDLE = await PushNotifications.addListener(
            "registration",
            async token => {
                REGISTRATION_RESULT.completed = true;
                REGISTRATION_RESULT.token = token.value;
            }
        );
        const REGISTRATION_ERROR_RESULT: { completed: boolean; error: string | null } = {
            completed: false,
            error: null
        };
        const REGISTRATION_ERROR_EVENT_HANDLE = await PushNotifications.addListener(
            "registrationError",
            error => {
                REGISTRATION_ERROR_RESULT.completed = true;
                REGISTRATION_ERROR_RESULT.error = error.error;
            }
        );

        const new_status = await PushNotifications.requestPermissions();
        permission_state.value = new_status.receive;

        if (new_status.receive !== "granted") {
            REGISTRATION_EVENT_HANDLE.remove();
            REGISTRATION_ERROR_EVENT_HANDLE.remove();

            return { success: false };
        }

        await PushNotifications.register();

        let can_continue = true;
        let iterations = 0;
        let final_result = false;

        while (can_continue) {
            iterations++;

            if (REGISTRATION_RESULT.completed && REGISTRATION_RESULT.token) {
                if (Capacitor.getPlatform() === "android") {
                    try {
                        await PushNotifications.createChannel({
                            id: PushNotificationsChannel.APPOINTMENTS,
                            name: "Wizyty",
                            description:
                                "Otrzymuj powiadomienia o nadchodzących wizytach, nowych wizytach zaplanowanych przez terapeutę, zbliżających się terminach płatności i inne dotyczące wizyt w ramach procesu terapii.",
                            sound: "notification_alert_1.mp3",
                            importance: 5,
                            visibility: 0,
                            lights: true,
                            lightColor: "#64a286",
                            vibration: true
                        });
                        await PushNotifications.createChannel({
                            id: PushNotificationsChannel.DEFAULT,
                            name: "Ogólne",
                            description: "Powiadomienia ogólne, bez konkretnej kategorii.",
                            sound: "notification_alert_1.mp3",
                            importance: 3,
                            visibility: 0,
                            lights: true,
                            lightColor: "#64a286",
                            vibration: true
                        });
                    } catch (err) {
                        console.error("Failed to create push notifications channel:", err);
                    }
                }

                if (auth_store.user_device) {
                    try {
                        const r = await httpJsonRequest<ApiPushSubscriptionResponse>(
                            `/user-devices/${auth_store.user_device._id}/push-subscription`,
                            {
                                method: "POST",
                                body: JSON.stringify({
                                    provider:
                                        Capacitor.getPlatform() === "ios"
                                            ? PushSubscriptionProvider.APN
                                            : PushSubscriptionProvider.GCM,
                                    registration_token: REGISTRATION_RESULT.token
                                })
                            },
                            { supress_errors: true }
                        );
                        auth_store.user_device = r.user_device;
                        final_result = true;
                    } catch (err) {
                        console.error("Failed to subscribe to push notifications:", err);
                    }
                }
                break;
            }

            if (REGISTRATION_ERROR_RESULT.completed) {
                console.error("Could not retrive push notifications token: ");
                console.error(REGISTRATION_ERROR_RESULT.error);
                break;
            }

            if (iterations >= 30) {
                can_continue = false;
            }

            await waitForMs(500);
        }

        REGISTRATION_EVENT_HANDLE.remove();
        REGISTRATION_ERROR_EVENT_HANDLE.remove();

        return { success: final_result };
    }

    async function openNativeNotificationsSettings() {
        const result = await NativeSettings.open({
            optionAndroid: AndroidSettings.AppNotification,
            optionIOS: IOSSettings.App
        });

        if (!result.status) {
            displayAlert.error({
                message: "Nieoczekiwany błąd, nie udało się przejść do ustawień"
            });
        }
    }

    onMounted(async () => {
        const app_state_listener = await App.addListener("appStateChange", async ({ isActive }) => {
            if (isActive) {
                await checkPermissions();
            }
        });

        CAPACITOR_EVENT_LISTENERS_HANDLES.push(app_state_listener);
        permission_state.value = (await PushNotifications.checkPermissions()).receive;
    });

    onUnmounted(() => {
        CAPACITOR_EVENT_LISTENERS_HANDLES.forEach(h => h.remove());
    });

    return {
        register,
        unregister,
        permission_state,
        checkPermissions,
        openNativeNotificationsSettings
    };
}
