<template>
    <RisifyInput
        :counter="counter"
        :hint="hint"
        :label="label"
        :id="id"
        :rules="rules"
        :show-asterisk="showAsterisk"
        :value="v"
        :class-name="className"
        :dense="dense"
        :disabled="disabled"
        v-model:error="error_state"
        @click:append-outer="e => emit('click:append-outer', e)"
        @click:prepend-outer="e => emit('click:prepend-outer', e)"
        @keydown="(e : KeyboardEvent) => emit('keydown', e)"
        ref="custom_input_ref"
    >
        <template
            v-if="hasSlot('label')"
            v-slot:label
        >
            <slot name="label"></slot>
        </template>
        <template
            v-if="hasSlot('hint')"
            v-slot:hint
        >
            <slot name="hint"></slot>
        </template>

        <template
            v-if="hasSlot('prepend-outer')"
            v-slot:prepend-outer
        >
            <slot name="prepend-outer"></slot>
        </template>
        <div
            class="risify-textarea text-link-1"
            :class="{
                'risify-textarea--disabled': disabled,
                'risify-textarea--focused': focused,
                'risify-textarea--invalid': error_state,
                'risify-textarea--outlined': outlined,
                'risify-textarea--dense': dense,
                ['risify-textarea--color-' + color]: true
            }"
            @click.stop="textarea_ref?.focus()"
            ref="main_ref"
        >
            <div
                class="risify-textarea__tfield"
                :class="{
                    'risify-textarea__tfield--autogrow': autogrow
                }"
                :style="{
                    '--customtxa-rows': max_rows,
                    '--customtxa-min-rows': min_rows
                }"
            >
                <textarea
                    v-model="v"
                    @input="
                        e => {
                            onInput(e);
                            resizeTextarea();
                        }
                    "
                    @keydown="e => emit('keydown', e)"
                    :disabled="disabled"
                    :readonly="readonly"
                    class="risify-textarea__input text-link-1"
                    @focus="handleFocus"
                    @blur="handleBlur"
                    :id="id"
                    :name="name"
                    ref="textarea_ref"
                    :style="autogrow ? textarea_styles : {}"
                    :rows="autogrow ? 1 : max_rows"
                ></textarea>
                <div
                    class="risify-textarea__placeholder"
                    v-if="is_empty"
                >
                    {{ placeholder }}
                </div>
            </div>
        </div>
        <template
            v-if="hasSlot('append-outer')"
            v-slot:append-outer
        >
            <slot name="append-outer"></slot>
        </template>
    </RisifyInput>
</template>

<script setup lang="ts">
import { ref, computed, useSlots, nextTick, watch } from "vue";

import RisifyInput, { RisifyInputProps } from "@/components/form-inputs/RisifyInput.vue";

type RisifyTextareaProps = Omit<RisifyInputProps<string>, "value"> & {
    name?: string;
    placeholder?: string;
    modelValue: string;
    rows?: number;
    autogrowMinRows?: number;
    autogrow?: boolean;
    outlined?: boolean;
    readonly?: boolean;
};

export type RisifyTextareaExpose = {
    validate: () => boolean;
    resetValidation: () => void;
};

/*###########
### SETUP ###
###########*/
const props = withDefaults(defineProps<RisifyTextareaProps>(), {
    disabled: false,
    rows: 4,
    autogrow: false,
    color: "default"
});

const emit = defineEmits<{
    (e: "update:modelValue", v: string): void;
    (e: "keydown", event: KeyboardEvent): void;
    (e: "click:append-outer", event: MouseEvent | PointerEvent): void;
    (e: "click:prepend-outer", event: MouseEvent | PointerEvent): void;
    (e: "focus", event: FocusEvent): void;
    (e: "blur", event: FocusEvent): void;
}>();

defineOptions({
    inheritAttrs: false
});

defineExpose({
    validate,
    resetValidation
});

/*###########
### HOOKS ###
###########*/
const slots = useSlots();

/*###############
### VARIABLES ###
###############*/
const v = ref<string>("");
const focused = ref<boolean>(false);
const error_state = ref<boolean>(false);
const BASE_LINE_HEIGHT = 20;

// element refs
const textarea_ref = ref<HTMLElement>();
const custom_input_ref = ref<any>();
const main_ref = ref<HTMLElement>();

const textarea_styles = ref<Record<string, string>>({
    "height": BASE_LINE_HEIGHT + "px",
    "overflow-y": "auto"
});

/*##############
### COMPUTED ###
##############*/
const max_rows = computed(() => {
    return Math.floor(props.rows);
});
const min_rows = computed(() => {
    return props.autogrowMinRows && props.autogrowMinRows > 1
        ? Math.floor(props.autogrowMinRows)
        : 1;
});
const is_empty = computed(() => {
    return v.value === "";
});

/*##############
### WATCHERS ###
##############*/
watch(
    () => props.modelValue,
    () => {
        handleValue();
        resizeTextarea();
    },
    { immediate: true }
);
watch(() => props.rows, resizeTextarea, { immediate: true });
watch(() => props.autogrow, resizeTextarea, { immediate: true });
watch(() => props.autogrowMinRows, resizeTextarea, { immediate: true });
watch(() => props.error, handleErrorPropChange, { immediate: true });

/*#############
### METHODS ###
#############*/
function validate() {
    if (!custom_input_ref.value) return false;
    return custom_input_ref.value.validate();
}
function resetValidation() {
    if (!custom_input_ref.value) return;
    return custom_input_ref.value.resetValidation();
}

function onInput(ev: any) {
    v.value = (ev.target as HTMLInputElement).value;
    emit("update:modelValue", v.value);
    nextTick(validate);
}

function handleValue() {
    if (props.modelValue != undefined) {
        v.value = props.modelValue;
    }
}

function handleFocus(ev: any) {
    focused.value = true;
    emit("focus", ev);
}

function handleBlur(ev: any) {
    focused.value = false;
    emit("blur", ev);
}

function resizeTextarea() {
    if (!props.autogrow) return;

    let el_py = 34;
    if (props.dense) {
        el_py = 26;
    }

    textarea_styles.value.height = "auto";
    nextTick(() => {
        if (!textarea_ref.value) return;

        const realHeight = textarea_ref.value.scrollHeight;
        const MAX_HEIGHT = BASE_LINE_HEIGHT * max_rows.value + el_py;
        const MIN_HEIGHT = BASE_LINE_HEIGHT * min_rows.value + el_py;

        if (realHeight) {
            if (realHeight > MAX_HEIGHT) {
                textarea_styles.value.height = `${MAX_HEIGHT}px`;
                textarea_styles.value["overflow-y"] = "auto";
            } else {
                if (realHeight < MIN_HEIGHT) {
                    textarea_styles.value.height = `${MIN_HEIGHT}px`;
                } else {
                    textarea_styles.value.height = `${realHeight}px`;
                }
                textarea_styles.value["overflow-y"] = "hidden";
            }
        }
    });
}

function hasSlot(name: "label" | "hint" | "prepend-outer" | "append-outer") {
    return !!slots[name];
}

function handleErrorPropChange(nv: boolean | undefined) {
    if (nv === false || nv === true) {
        error_state.value = nv;
    }
}
</script>
