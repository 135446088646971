<template>
    <FullscreenOverlay
        v-model="app_update_dialog"
        :width="960"
        class="auto-updater-native-dialog"
    >
        <div class="auto-updater-native-dialog__nav text-center text-h7 text-weight-semibold">
            Informacja o aktualizacji
        </div>
        <div class="auto-updater-native-dialog__main">
            <div class="auto-updater-native-dialog__logo mx-auto mb-4">
                <img :src="risifyLogoIcon" />
            </div>
            <div class="text-center">
                <div class="text-h4 mb-4">Dostępna jest nowa wersja aplikacji Risify</div>
                <div class="text-body-2">
                    Przejdź do sklepu z aplikacjami i pobierz najnowszą wersję, aby uzyskać dostęp
                    do najnowszych funkcjonalności i poprawek błędów.
                </div>
            </div>
        </div>
        <div class="auto-updater-native-dialog__footer">
            <div class="auto-updater-native-dialog__actions">
                <RisifyButton
                    color="green"
                    class="auto-updater-native-dialog__btn"
                    block
                    @click="
                        () => {
                            handleAppUpdaterSnooze();
                            handleAppUpdateRedirect();
                            app_update_dialog = false;
                        }
                    "
                >
                    Aktualizuj aplikację
                </RisifyButton>
                <RisifyButton
                    color="dark-green"
                    text
                    @click="
                        () => {
                            handleAppUpdaterSnooze();
                            app_update_dialog = false;
                        }
                    "
                >
                    Nie teraz
                </RisifyButton>
            </div>
        </div>
    </FullscreenOverlay>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useGeneralStore } from "@/stores/general";
import { PREFERENCES_KEYS } from "@/stores/constants";

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { AppUpdate } from "@capawesome/capacitor-app-update";

import RisifyButton from "@/components/buttons/RisifyButton.vue";
import FullscreenOverlay from "@/components/dialogs/FullscreenOverlay.vue";

import risifyLogoIcon from "@/assets/imgs/risify_logo_icon.svg";
import { waitForMs } from "@/helpers/waiters";

/*###########
### HOOKS ###
###########*/
const general_store = useGeneralStore();

/*#############
### GENERAL ###
#############*/
const APP_UPDATE_BLOCK_DURATION = 7 * 24 * 60 * 60 * 1000;

async function handleAppUpdaterSnooze() {
    const expiry_timestamp = Date.now() + APP_UPDATE_BLOCK_DURATION;
    app_update_dialog_expiry.value = expiry_timestamp;

    await Preferences.set({
        key: PREFERENCES_KEYS.AUTOUPDATER_NATIVE_LAST_CHECK_DATE,
        value: expiry_timestamp.toString()
    });
}
/*#######################
### DIALOG DISPLAYING ###
#######################*/
const app_update_dialog = ref<boolean>(false);
const app_update_dialog_expiry = ref<number | null>(null);

async function displayIfEligibleAppNativeUpdateDialog() {
    await waitForMs(500);

    const APP_INFO = await getAppUpdateInfo();
    if (!APP_INFO) return;

    const { value } = await Preferences.get({
        key: PREFERENCES_KEYS.AUTOUPDATER_NATIVE_LAST_CHECK_DATE
    });
    app_update_dialog_expiry.value = value ? Number(value) : null;
    if (app_update_dialog_expiry.value && Date.now() < app_update_dialog_expiry.value) return;

    const CURRENT_VERSION =
        Capacitor.getPlatform() === "android"
            ? APP_INFO.currentVersionCode
            : APP_INFO.currentVersionName;
    const REMOTE_VERSION =
        Capacitor.getPlatform() === "android"
            ? APP_INFO.availableVersionCode
                ? APP_INFO.availableVersionCode
                : undefined
            : APP_INFO.availableVersionName
            ? APP_INFO.availableVersionName
            : undefined;

    if (REMOTE_VERSION === undefined || REMOTE_VERSION == CURRENT_VERSION) return;

    app_update_dialog.value = true;
}

/*#################
### APP VERSION ###
#################*/
async function getAppUpdateInfo() {
    try {
        const result = await AppUpdate.getAppUpdateInfo({ country: "PL" });
        return result;
    } catch (err) {
        console.error("[AutoUpdaterNative] Could not retrieve AppUpdateInfo");
        console.error(err);
    }
    return undefined;
}

async function handleAppUpdateRedirect() {
    await AppUpdate.openAppStore();
}

/*#####################
### LIFECYCLE HOOKS ###
#####################*/
onMounted(() => {
    if (!Capacitor.isNativePlatform()) return;
    if (general_store.is_online) {
        displayIfEligibleAppNativeUpdateDialog();
    }
});
</script>
