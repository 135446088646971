export enum PushNotificationName {
    NEXT_APPOINTMENT_SCHEDULE_REMINDER = "next_appointment_schedule_reminder"
}

type PushNotificationRisifyCustomDataBase = {
    user_id: string;
};
type PushNotificationRisifyCustomDataNextAppointmentScheduleReminder =
    PushNotificationRisifyCustomDataBase & {
        name: PushNotificationName.NEXT_APPOINTMENT_SCHEDULE_REMINDER;
        last_completed_appointment_id: string;
    };

export type PushNotificationRisifyCustomData =
    PushNotificationRisifyCustomDataNextAppointmentScheduleReminder;

