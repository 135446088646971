<template>
    <div class="default-layout">
        <UiAlerts />
        <MobileMenu :visible="mobile_menu" />
        <AppointmentDetailsView />
        <div
            class="default-layout-content"
            ref="layout_content"
        >
            <slot />
        </div>
        <BottomNavigation v-if="router_ready && show_bottom_navigation" />

        <div
            :class="{
                'default-layout-nsp text-link-3': true,
                'default-layout-nsp--visible': show_network_status_prompt,
                'default-layout-nsp default-layout-nsp--online': generalStore.is_online,
                'default-layout-nsp default-layout-nsp--offline': !generalStore.is_online
            }"
        >
            {{ generalStore.is_online ? "Odzyskano połączenie" : "Brak połączenia" }}
        </div>
    </div>

    <Dialog
        v-model="show_offline_dialog"
        persistent
    >
        <DialogBaseHeader>Jesteś offline</DialogBaseHeader>
        <DialogBaseBody
            >Aplikacja nie ma&nbsp;połączenia z&nbsp;Internetem&nbsp;- dopóki połączenie
            nie&nbsp;zostanie nawiązane, nie ma&nbsp;możliwości przeprowadzenia żadnych operacji,
            a&nbsp;wyświetlane w&nbsp;aplikacji dane pochodzą z&nbsp;pamięci cache.</DialogBaseBody
        >
        <template #footer>
            <div class="default-layout-offline-dlg__footer">
                <DialogBaseFooter>
                    <RisifyButton
                        class="ml-auto"
                        color="yellow"
                        @click="show_offline_dialog = false"
                        >Zamknij</RisifyButton
                    >
                </DialogBaseFooter>
            </div>
        </template>
    </Dialog>
    <!-- MOBILE APP ALERT DIALOG -->
    <Dialog
        v-model="mobile_app_alert_dialog"
        persistent
        class="default-layout-mobile-app-alert-dlg"
    >
        <template v-slot:default>
            <DialogBaseBody>
                <div class="default-layout-mobile-app-alert-dlg__logo mx-auto mb-6">
                    <img :src="risifyLogoIcon" />
                </div>
                <div class="text-center">
                    <div class="text-body-2 text-weight-semibold mb-4">
                        <span class="text-weight-bold">Risify</span> jest lepsze w&nbsp;naszej
                        aplikacji&nbsp;mobilnej
                    </div>
                    <div class="text-link-3 mb-8">
                        Przejdź do&nbsp;aplikacji, aby&nbsp;uzyskać lepsze wrażenia, w&nbsp;tym
                        szybkie rezerwacje w&nbsp;bezpiecznym środowisku i&nbsp;intuicyjny
                        interfejs, który&nbsp;sprawia, że&nbsp;korzystanie jest czystą
                        przyjemnością!
                    </div>
                </div>
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter>
                <div class="default-layout-mobile-app-alert-dlg__footer">
                    <RisifyButton
                        class="default-layout-mobile-app-alert-dlg__btn"
                        @click="handleStayInBrowser"
                        text
                        small
                        block
                        color="gray"
                        >Zostaję w przeglądarce</RisifyButton
                    >
                    <RisifyButton
                        @click="handleDownloadMobileApp"
                        block
                        class="mb-4"
                        >Pobierz aplikację</RisifyButton
                    >
                </div>
            </DialogBaseFooter>
        </template>
    </Dialog>
    <!-- PUSH NOTIFICATIONS DIALOG -->
    <Dialog
        v-if="appointment_latest"
        v-model="show_push_notification_dialog"
        persistent
        class="default-layout-push-notification-dlg"
    >
        <DialogBaseHeader
            @close="show_push_notification_dialog = false"
            close-button
        >
            Przypomnienie
        </DialogBaseHeader>
        <DialogBaseBody>
            <div class="mb-2">
                Nie zapomnij o&nbsp;swojej&nbsp;kolejnej&nbsp;wizycie!
                Zarezerwuj&nbsp;dogodny&nbsp;termin już&nbsp;teraz
                i&nbsp;zadbaj&nbsp;o&nbsp;ciągłość procesu.👌 Kliknij, aby&nbsp;umówić wizytę!
            </div>

            <div
                class="text-label-2 mb-1"
                style="text-transform: uppercase"
            >
                Twoja ostatnia wizyta
            </div>
            <div class="default-layout-push-notification-dlg__apptile pa-2">
                <div class="text-body-2">
                    {{ appointment_latest.product.name }}
                </div>
                <div class="default-layout-push-notification-dlg__thtile my-2">
                    <Avatar
                        size="24"
                        :image-src="appointment_latest.host.profile_image?.url_xs"
                    />
                    <div class="ml-2 text-link-3 text-weight-semibold">
                        {{ appointment_latest.host.first_name }}
                        {{ appointment_latest.host.last_name }}
                    </div>
                </div>
                <div class="text-link-3">
                    {{ formatDate(appointment_latest.start_time, "l, j M, H:i") }}
                </div>
            </div>
        </DialogBaseBody>
        <template #footer>
            <div class="default-layout-push-notification-dlg__footer">
                <DialogBaseFooter>
                    <RisifyButton
                        class="ml-auto"
                        color="yellow"
                        block
                        @click="
                            router.push({
                                name: 'redirect-push-notification',
                                params: {
                                    id: appointment_latest._id
                                }
                            });
                            show_push_notification_dialog = false;
                        "
                        >Umów wizytę</RisifyButton
                    >
                </DialogBaseFooter>
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralStore } from "@/stores/general";
import { emitter } from "@/plugins/eventEmitter";
import AppointmentDetailsView from "@/partials/AppointmentDetailsView.vue";
import BottomNavigation from "@/components/navigation/BottomNavigation.vue";
import Dialog from "@/components/dialogs/Dialog.vue";
import DialogBaseHeader from "@/components/dialogs/DialogBaseHeader.vue";
import DialogBaseBody from "@/components/dialogs/DialogBaseBody.vue";
import DialogBaseFooter from "@/components/dialogs/DialogBaseFooter.vue";
import MobileMenu from "@/components/navigation/MobileMenu.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";
import UiAlerts from "@/partials/UiAlerts.vue";
import { EmitterDefaultLayoutScrollLayoutArgs } from "@/types/emitter";
import { useDefaultLayout } from "@/helpers/layout";
import { useWindowSize } from "@vueuse/core";
import risifyLogoIcon from "@/assets/imgs/risify_logo_icon.svg";
import { detect } from "detect-browser";
import { Preferences } from "@capacitor/preferences";
import { PREFERENCES_KEYS } from "@/stores/constants";
import { PushNotificationSchema } from "@capacitor/push-notifications";
import { Appointment } from "@/types/appointment";
import { useFetchedElementsStore } from "@/stores/fetched-elements";
import Avatar from "@/components/generics/Avatar.vue";
import { formatDate } from "@/helpers/formatters";

/*###########
### SETUP ###
###########*/
const generalStore = useGeneralStore();
const router = useRouter();
const route = useRoute();
const { is_bottom_navigation_visible } = useDefaultLayout();
const { width: window_width } = useWindowSize();
const browser = detect();

/*#############################
### MOBILE APP ALERT DIALOG ###
#############################*/
const mobile_app_alert_expiry = ref<number | null>(null);
const mobile_app_alert_dialog = ref<boolean>(false);

const MOBILE_APP_ALERT_BLOCK_DURATION = 7 * 24 * 60 * 60 * 1000;
const MOBILE_APP_ALERT_ANDROID_LINK =
    "https://play.google.com/store/apps/details?id=pl.pravna.smane";
const MOBILE_APP_ALERT_IOS_LINK =
    "https://apps.apple.com/us/app/anonimowa-poradnia-rozwodowa/id6456223508";
// const MOBILE_APP_ALERT_VISIBLE_ROUTES = [
//     "home",
//     "appointments",
//     "chat",
//     "chats",
//     "help-center",
//     "help-center-helpdesk-thread",
//     "knowledge-blog",
//     "knowledge-blog-post"
// ];

async function handleStayInBrowser() {
    mobile_app_alert_dialog.value = false;
    const expiry_timestamp = updateMobileAppAlertExpiry();

    await Preferences.set({
        key: PREFERENCES_KEYS.MOBILE_ALERT_DIALOG_TS,
        value: expiry_timestamp.toString()
    });
}

function handleDownloadMobileApp() {
    mobile_app_alert_dialog.value = false;
    updateMobileAppAlertExpiry();

    if (browser && browser.os === "iOS") {
        window.open(MOBILE_APP_ALERT_IOS_LINK, "_blank");
    } else if (browser && browser.os === "Android OS") {
        window.open(MOBILE_APP_ALERT_ANDROID_LINK, "_blank");
    }
}

function updateMobileAppAlertExpiry() {
    const expiry_timestamp = Date.now() + MOBILE_APP_ALERT_BLOCK_DURATION;
    mobile_app_alert_expiry.value = expiry_timestamp;
    return expiry_timestamp;
}

// TODO: odkomentować, aby włączyć
function displayIfEligibleMobileAppAlertDialog() {
    //   if (
    //     !Capacitor.isNativePlatform() &&
    //     Capacitor.getPlatform() === 'web' &&
    //     checkRouteEligibleForMobileAppAlert() &&
    //     browser &&
    //     (browser.os === 'Android OS' || browser.os === 'iOS') &&
    //     (!mobile_app_alert_expiry.value || Date.now() > mobile_app_alert_expiry.value)
    //   ) {
    //     mobile_app_alert_dialog.value = true;
    //   } else {
    //     mobile_app_alert_dialog.value = false;
    //   }
}

// function checkRouteEligibleForMobileAppAlert() {
//     if (typeof route.name !== 'string') return false
//     return MOBILE_APP_ALERT_VISIBLE_ROUTES.includes(route.name)
// }

/*###############
### VARIABLES ###
############## */
const layout_content = ref<HTMLElement | null>(null);

const mobile_menu = ref<boolean>(false);
const bottom_navigation_hidden_manually = ref(false);
const router_ready = ref(false);

watch(
    () => route.name,
    () => {
        displayIfEligibleMobileAppAlertDialog();
    }
);

/*###########################
### NETWORK STATUS PROMPT ###
########################## */
// const network_status_prompt_ref = ref<HTMLElement | null>(null);
const show_network_status_prompt = ref(false);

// const network_status_prompt_height = computed(() => {
//     if (network_status_prompt_ref.value) {
//         return network_status_prompt_ref.value.offsetHeight || 0;
//     }
// });

/*####################
### OFFLINE DIALOG ###
################### */
const show_offline_dialog = ref(false);

const showOfflineDialog = () => {
    show_offline_dialog.value = true;
};

/*###############################
### PUSH NOTIFICATIONS DIALOG ###
############################## */
const show_push_notification_dialog = ref<boolean>(false);
const push_notification = ref<PushNotificationSchema>();
const push_notification_rispn = ref<PushNotificationSchema>();
const appointment_latest = ref<Appointment>();
const appointment_latest_loading = ref<boolean>(false);
const fetched_elements = useFetchedElementsStore();

const showPushNotificationDialog = (notification: PushNotificationSchema) => {
    if (notification.data.rispn) {
        push_notification_rispn.value = notification;
        getLatestAppointment(notification.data.rispn.last_completed_appointment_id);
    } else {
        push_notification.value = notification;
        getLatestAppointment(notification.data.last_completed_appointment_id);
    }
    show_push_notification_dialog.value = true;
};

async function getLatestAppointment(id: string) {
    if (appointment_latest_loading.value) return;

    appointment_latest_loading.value = true;

    try {
        const APP = await fetched_elements.getAppointment(id);
        appointment_latest.value = APP;
    } catch (err) {
        console.error(err);
    }

    appointment_latest_loading.value = false;
}

/*##############
### COMPUTED ###
############# */
const show_bottom_navigation = computed(() => {
    return !bottom_navigation_hidden_manually.value && is_bottom_navigation_visible.value;
});

/*##############
### WATCHERS ### 
##############*/
watch(window_width, calcMainLayoutScrollbarWidth, {
    immediate: true
});

watch(
    () => generalStore.is_online,
    nv => {
        if (nv) {
            show_network_status_prompt.value = true;
            setTimeout(() => {
                show_network_status_prompt.value = false;
            }, 2500);
        } else {
            show_network_status_prompt.value = true;
        }
    }
);

/*#############
### METHODS ###
############ */
function handleLayoutScroll() {
    generalStore.setMainLayoutLastScrollPosition(layout_content.value?.scrollTop || 0);
    generalStore.setMainLayoutLastScrollHeight(layout_content.value?.scrollHeight || 0);
    generalStore.setMainLayoutLastOffsetHeight(layout_content.value?.offsetHeight || 0);
}

function scrollLayout(y: number, animate: boolean = false) {
    layout_content.value?.scrollTo({
        left: 0,
        top: y,
        behavior: animate ? "smooth" : "instant"
    });
}

function calcMainLayoutScrollbarWidth() {
    if (layout_content.value) {
        generalStore.setMainLayoutClientWidth(layout_content.value.clientWidth);
        generalStore.setMainLayoutScrollbarWidth(
            layout_content.value.offsetWidth - layout_content.value.clientWidth
        );
    } else {
        generalStore.setMainLayoutClientWidth(window_width.value);
    }
}

function hideBottomNavigation() {
    bottom_navigation_hidden_manually.value = true;
}
function showBottomNavigation() {
    bottom_navigation_hidden_manually.value = false;
}

function hideMobileMenu(): void {
    mobile_menu.value = false;
}

function showMobileMenu(): void {
    mobile_menu.value = true;
}

function onEmitterScrollLayout(args: EmitterDefaultLayoutScrollLayoutArgs) {
    scrollLayout(args.y, args.animate);
}

/*###########
### HOOKS ###
########## */
onMounted(async () => {
    generalStore.setMainLayoutLastScrollPosition(0);

    emitter.on("DefaultLayout::scrollLayout", onEmitterScrollLayout);
    emitter.on("DefaultLayout::hideBottomNavigation", hideBottomNavigation);
    emitter.on("DefaultLayout::showBottomNavigation", showBottomNavigation);
    emitter.on("DefaultLayout::hideMobileMenu", hideMobileMenu);
    emitter.on("DefaultLayout::showMobileMenu", showMobileMenu);
    emitter.on("DefaultLayout::showOfflineDialog", showOfflineDialog);
    emitter.on("DefaultLayout::showPushNotificationDialog", showPushNotificationDialog);

    layout_content.value?.addEventListener("scroll", handleLayoutScroll, {
        passive: true
    });

    calcMainLayoutScrollbarWidth();

    router.isReady().then(() => {
        router_ready.value = true;
    });

    // Sprawdzamy czy istnieje zapis w LS dot. czasy wyświetlenia mobile alert dialogu
    const { value } = await Preferences.get({
        key: PREFERENCES_KEYS.MOBILE_ALERT_DIALOG_TS
    });
    mobile_app_alert_expiry.value = value ? Number(value) : null;
    // Sprawdzamy czy wyswietlić dialog
    displayIfEligibleMobileAppAlertDialog();
});

onUnmounted(() => {
    layout_content.value?.removeEventListener("scroll", handleLayoutScroll);
    emitter.off("DefaultLayout::scrollLayout", onEmitterScrollLayout);
    emitter.off("DefaultLayout::hideBottomNavigation", hideBottomNavigation);
    emitter.off("DefaultLayout::showBottomNavigation", showBottomNavigation);
    emitter.off("DefaultLayout::hideMobileMenu", hideMobileMenu);
    emitter.off("DefaultLayout::showMobileMenu", showMobileMenu);
    emitter.off("DefaultLayout::showOfflineDialog", showOfflineDialog);
    emitter.off("DefaultLayout::showPushNotificationDialog", showPushNotificationDialog);
});

defineExpose({
    scrollLayout,
    hideBottomNavigation,
    showBottomNavigation
});
</script>
