import { ApiCursorPaginatedResponse, ApiSuccessResponse } from "./api-http-requests";
import { UserPublicDataFragment } from "./user";

export enum ChatMessageAuthorType {
    USER = "usr",
    SYSTEM = "sys"
}
export enum ChatMessageMessageType {
    TEXT = "text",
    HTML = "html",
    FILE = "file",
    EVENT = "event"
}
export enum ChatMessageFileDisplayType {
    IMAGE = "img",
    PDF = "pdf",
    WORD = "word",
    UNKNOWN = "unknown"
}
export enum ChatMessageEventName {
    CHAT_CREATED = "chat_created",
    CHAT_USER_JOINED = "chat_user_joined",
    CHAT_USER_KICKED = "chat_user_kicked",
    CHAT_USER_RESUMED = "chat_user_resumed",

    APPOINTMENT_EPF_SCHEDULED = "appointment_epf_scheduled",
    APPOINTMENT_MPF_SCHEDULED = "appointment_mpf_scheduled",
    APPOINTMENT_MPF_PAID = "appointment_mpf_paid",
    APPOINTMENT_RESCHEDULED = "appointment_rescheduled",
    APPOINTMENT_CANCELED = "appointment_canceled",
    APPOINTMENT_STARTED = "appointment_started",
    APPOINTMENT_COMPLETED = "appointment_completed",

    APPOINTMENT_RESCHEDULE_PROPOSAL_CREATED = "arp_created"
}
export const ChatMessageEventNameLabel: Record<string, string> = Object.freeze({
    [ChatMessageEventName.CHAT_CREATED]: "Rozpoczęcie rozmowy",
    [ChatMessageEventName.CHAT_USER_JOINED]: "Dołączenie do rozmowy",
    [ChatMessageEventName.CHAT_USER_KICKED]: "Wyrzucenie z rozmowy",
    [ChatMessageEventName.CHAT_USER_RESUMED]: "Wznowienie rozmowy",

    [ChatMessageEventName.APPOINTMENT_EPF_SCHEDULED]: "Zaplanowano i opłacono wizytę",
    [ChatMessageEventName.APPOINTMENT_MPF_SCHEDULED]: "Zaplanowano wizytę",
    [ChatMessageEventName.APPOINTMENT_MPF_PAID]: "Opłacono wizytę",
    [ChatMessageEventName.APPOINTMENT_RESCHEDULED]: "Przełożono wizytę",
    [ChatMessageEventName.APPOINTMENT_CANCELED]: "Odwołano wizytę",
    [ChatMessageEventName.APPOINTMENT_STARTED]: "Rozpoczęto wizytę",
    [ChatMessageEventName.APPOINTMENT_COMPLETED]: "Zakończono wizytę",

    [ChatMessageEventName.APPOINTMENT_RESCHEDULE_PROPOSAL_CREATED]:
        "Zaproponowano nowy termin wizyty"
});

type ChatMessageEventDetailsPlain = {
    name: ChatMessageEventName.CHAT_CREATED;
};
type ChatMessageEventDetailsWithUser = {
    name:
        | ChatMessageEventName.CHAT_USER_JOINED
        | ChatMessageEventName.CHAT_USER_KICKED
        | ChatMessageEventName.CHAT_USER_RESUMED;
    subject_user: string;
};
type ChatMessageEventDetailsWithAppointment = {
    name:
        | ChatMessageEventName.APPOINTMENT_EPF_SCHEDULED
        | ChatMessageEventName.APPOINTMENT_MPF_SCHEDULED
        | ChatMessageEventName.APPOINTMENT_MPF_PAID
        | ChatMessageEventName.APPOINTMENT_CANCELED
        | ChatMessageEventName.APPOINTMENT_STARTED;
    subject_appointment: string;
};
type ChatMessageEventDetailsWithAppointmentAndPstix = {
    name: ChatMessageEventName.APPOINTMENT_RESCHEDULED;
    subject_appointment: string;
    pstix: number;
};
type ChatMessageEventDetailsWithAppointmentAndAppvc = {
    name: ChatMessageEventName.APPOINTMENT_COMPLETED;
    subject_appointment: string;
    appvc_chat: string;
};
type ChatMessageEventDetailsWithArp = {
    name: ChatMessageEventName.APPOINTMENT_RESCHEDULE_PROPOSAL_CREATED;
    subject_arp: string;
};

type ChatMessageEventDetails =
    | ChatMessageEventDetailsPlain
    | ChatMessageEventDetailsWithUser
    | ChatMessageEventDetailsWithAppointment
    | ChatMessageEventDetailsWithAppointmentAndPstix
    | ChatMessageEventDetailsWithAppointmentAndAppvc
    | ChatMessageEventDetailsWithArp;

type ChatMessageBase = {
    _id: string;
    chat: string;
    public: boolean;
    c_date: number;
    m_date: number;
};
type ChatMessageFromUser = ChatMessageBase & {
    author_type: ChatMessageAuthorType.USER;
    user: UserPublicDataFragment;
};
type ChatMessageFromSystem = ChatMessageBase & {
    author_type: ChatMessageAuthorType.SYSTEM;
};
type ChatMessageWithAuthor = ChatMessageFromUser | ChatMessageFromSystem;

type ChatMessageDeleted = ChatMessageWithAuthor & {
    deleted: true;
};
type ChatMessageTextType = ChatMessageWithAuthor & {
    deleted: false;
    message_type: ChatMessageMessageType.TEXT;
    text: {
        content: string;
    };
};
type ChatMessageHtmlType = ChatMessageWithAuthor & {
    deleted: false;
    message_type: ChatMessageMessageType.HTML;
    html: {
        content: string;
    };
};
type ChatMessageEventType = ChatMessageWithAuthor & {
    deleted: false;
    message_type: ChatMessageMessageType.EVENT;
    event: ChatMessageEventDetails;
};
export type ChatMessageFileType = ChatMessageWithAuthor & {
    deleted: false;
    message_type: ChatMessageMessageType.FILE;
    file: {
        name: string;
        display_type: ChatMessageFileDisplayType;
        mimetype: string;
        size: number;
    };
};
export type ChatMessage =
    | ChatMessageDeleted
    | ChatMessageTextType
    | ChatMessageHtmlType
    | ChatMessageFileType
    | ChatMessageEventType;

// #####################
// ### HTTP REQUESTS ###
// #####################
export type ApiChatMessagesResponse<T = ChatMessage> = ApiCursorPaginatedResponse & {
    chat_messages: T[];
};
export type ApiChatMessageResponse = ApiSuccessResponse & {
    chat_message: ChatMessage;
};
export type ApiChatMessageFileUrlResponse = ApiSuccessResponse & {
    url: string;
};

// ########################
// ### NEW CHAT MESSAGE ###
// ########################
export enum NotificationType {
    NEW_CHAT_MESSAGE = "new_chat_message"
}
export type NewChatMessageUIAlertProps = {
    notification_type: NotificationType.NEW_CHAT_MESSAGE;
    chat_message: ChatMessage;
};
