<template>
    <div class="progress-bar">
        <div class="progress-bar__container" :class="`progress-bar__container--${color}`">
            <div
                class="progress-bar__fill"
                :class="`progress-bar__fill--${color}`"
                :style="{ width: computedWidth }"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface ProgressBarProps {
    value: number;
    color?: "primary" | "kidme-primary";
}

const props = withDefaults(defineProps<ProgressBarProps>(), {
    value: 0,
    color: "primary"
});

const limitedValue = computed(() => Math.min(100, Math.max(0, props.value)));
const computedWidth = computed(() => `${limitedValue.value}%`);
</script>
