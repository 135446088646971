import {
    ApiCursorPaginatedResponse,
    ApiSuccessResponse,
    ApiErrorResponse
} from "./api-http-requests";
import { PaymentIntentCurrency } from "./payment-intent";
import { ProductTypeAppointment, ProductAvailabilitySlot } from "./product";
import { TherapistUser, UserUser } from "./user";

export enum AppointmentPreviousStartTimeRescheduledBy {
    HOST = "host",
    OWNER = "owner",
    ADMIN = "admin",
    SYSTEM = "system"
}

export enum AppointmentCanceledBy {
    HOST = "host",
    OWNER = "owner",
    ADMIN = "admin",
    SYSTEM = "system"
}

export enum AppointmentStatus {
    UNPAID = "unpaid",
    PAID = "paid",
    IN_PROGRESS = "in_progress",
    COMPLETED = "completed",
    CANCELED = "canceled"
}

export const AppointmentStatusLabel = Object.freeze({
    [AppointmentStatus.UNPAID]: "Nieopłacona",
    [AppointmentStatus.PAID]: "Opłacona i potwierdzona",
    [AppointmentStatus.IN_PROGRESS]: "W trakcie",
    [AppointmentStatus.COMPLETED]: "Zakończona",
    [AppointmentStatus.CANCELED]: "Anulowana"
});

export enum MindgramAppointmentCancellationReason {
    PERSONAL = "personal",
    URGENT = "urgent",
    CLIENT_MISMATCH = "client_mismatch",
    CLIENT_UNRELIABLE = "client_unreliable",
    CLIENT_CRITICAL = "client_critical",
    SPECIALIST_OTHER = "specialist_other"
}
export enum MindgramAppointmentCancellationUserRecommendation {
    APPOINTMENT_WITH_ME = "appointment_with_me",
    APPOINTMENT_ANOTHER_SPECIALIST = "appointment_another_specialist",
    CALL_EMERGENCY = "call_emergency",
    DO_NOTHING = "do_nothing"
}

export enum AppointmentPaymentFlowType {
    MPF = "mpf",
    EPF = "epf"
}

export enum AppointmentHostingType {
    RISIFY_SELF_HOST = "risify_self_host",
    MINDGRAM = "mindgram",
    VCITA_MIGRATION = "vcita_migration" // WIZYTY STARE ZMIGROWANE Z VCITA
}

export enum AppointmentSource {
    RISIFY = "risify",
    MINDGRAM = "mindgram",
    VCITA = "vcita"
}

export enum AppointmentCreatedBy {
    HOST = "host",
    OWNER = "owner",
    ADMIN = "admin",
    SYSTEM = "system"
}

export enum AppointmentVcProvider {
    TWILIO = "twilio"
}

export enum AppointmentRescheduleFlow {
    NONE = "none",
    ARP = "arp"
}

export enum AppointmentPriceNoPaymentReason {
    PRODUCT_CREDIT = "product_credit",
    DISCOUNT_CODE = "discount_code",
    MARKED_AS_PAID_BY_ADMIN = "masp_admin"
}

export enum AppointmentBookingProvider {
    NONE = "none",
    TIMERISE = "timerise",
    RISIFY_SERVICE_BOOKING = "ris_sb"
}

export enum AppointmentPredefinedNotification {
    CUSTOMER_NEGATIVE_FEEDBACK = "customer_negative_feedback"
}

export enum AppointmentAppSource {
    THERAPIST_CALENDAR = "therapist_calendar",
    THERAPIST_APPOINTMENTS_VIEW_CTA = "therapist_appointments_view_cta",
    THERAPIST_APPOINTMENT_DETAILS = "therapist_appointment_details",
    THERAPIST_VIDEOCALL = "therapist_videocall",
    THERAPIST_THUSAPPR_CHAT = "therapist_thusappr_chat",
    CUSTOMER_FN1_ONBOARDING = "customer_fn1_onboarding",
    CUSTOMER_FN2_ONBOARDING = "customer_fn2_onboarding",
    CUSTOMER_THUSAPPR_CHAT = "customer_thusappr_chat",
    CUSTOMER_FN1_FIRST_APPOINTMENT_DATE = "customer_fn1_first_appointment_date",
    CUSTOMER_FN1_SND = "customer_fn1_snd",
    CUSTOMER_FN1_SND_PRESELECTED = "customer_fn1_snd_preselected",
    CUSTOMER_FN1_RECOMMENDATION = "customer_fn1_recommendation",
    CUSTOMER_FN2_RECOMMENDATION = "customer_fn2_recommendation",
    CUSTOMER_THERAPIST_PAGE = "customer_therapist_page",
    CUSTOMER_POST_VC_SLOTS_WIDGET = "customer_post_vc_slots_widget",
    UNKNOWN = "unknown"
}

export const AppointmentPriceNoPaymentReasonLabel = Object.freeze({
    [AppointmentPriceNoPaymentReason.PRODUCT_CREDIT]:
        "Wizyta opłacona za pomocą kredytów produktowych (mechanizm przedpłat).",
    [AppointmentPriceNoPaymentReason.DISCOUNT_CODE]: "Wizyta opłacona przy pomocy kodu zniżkowego.",
    [AppointmentPriceNoPaymentReason.MARKED_AS_PAID_BY_ADMIN]:
        "Wizyta oznaczona jako opłacona przez Administrację aplikacji Risify."
});

export type AppointmentRisifySelfHost = {
    chat?: string;
};

type AppointmentPriceDetails = {
    amount: number;
    currency: PaymentIntentCurrency;
    npr?: AppointmentPriceNoPaymentReason;
};

export type AppointmentBase = {
    _id: string;
    product: ProductTypeAppointment;
    host: Pick<
        TherapistUser,
        "_id" | "first_name" | "last_name" | "profile_image" | "therapist_info"
    >;
    participants: Pick<
        UserUser,
        "_id" | "first_name" | "last_name" | "profile_image" | "user_thid"
    >[];
    owner: string;

    start_time: number;
    prev_start_times: [
        {
            start_time: number;
            rescheduled_by: AppointmentPreviousStartTimeRescheduledBy;
            reschedule_reason: string;
        }
    ];
    duration: string;
    end_time: number;
    hosting_type: AppointmentHostingType;
    mindgram?: {
        patient_email: string;
        patient_first_name: string;
        patient_last_name: string;
    };
    rated_by: Record<string, string>;
    price?: AppointmentPriceDetails;
    payment_flow_type: AppointmentPaymentFlowType;
    payment_deadline?: number;
    checkout_session?: string;
    source: AppointmentSource;
    created_by: AppointmentCreatedBy;
    c_date: number;
    m_date: number;
};

export type AppointmentUnpaidOrPaid = AppointmentBase & {
    status: AppointmentStatus.UNPAID | AppointmentStatus.PAID;
};
export type AppointmentCanceled = AppointmentBase & {
    status: AppointmentStatus.CANCELED;
    canceled_by: AppointmentCanceledBy;
    cancel_reason: string;
};
export type AppointmentInProgressOrCompleted = AppointmentBase & {
    hos_no: number;
    status: AppointmentStatus.IN_PROGRESS | AppointmentStatus.COMPLETED;
} & {
    hosting_type: AppointmentHostingType.RISIFY_SELF_HOST;
    risify_self_host: AppointmentRisifySelfHost;
};

export type Appointment =
    | AppointmentInProgressOrCompleted
    | AppointmentCanceled
    | AppointmentUnpaidOrPaid;

export interface AppointmentTileProps {
    outlined?: boolean;
    filled?: boolean;
    color?: "red" | "green" | "gray";
    highlighted?: boolean;
}

export type CalendarDay = {
    date: string;
    sessions: ProductAvailabilitySlot[];
};

export type AppointmentsByDayRecord = {
    count: number;
    unpaid_count: number;
    confirmed_count: number;
};

export type AppointmentTwilioRoomCredentials = {
    access_token: string;
    room_name: string;
};

export type AppointmentStartedWithTwilioResponseBody = {
    vc_provider: AppointmentVcProvider.TWILIO;
    twilio: AppointmentTwilioRoomCredentials;
};
export type AppointmentStartedWithMindgramResponseBody = {
    vc_provider: "mindgram";
    mindgram: {
        url: string;
    };
};
type AppointmentStartedResponseBody =
    | AppointmentStartedWithTwilioResponseBody
    | AppointmentStartedWithMindgramResponseBody;

// #####################
// ### HTTP REQUESTS ###
// #####################
export type ApiAppointmentResponse = ApiSuccessResponse & {
    appointment: Appointment;
};
export type ApiAppointmentsResponse = ApiCursorPaginatedResponse & {
    appointments: Appointment[];
};
export type ApiAppointmentStartedResponse =
    | ApiErrorResponse
    | (ApiSuccessResponse & AppointmentStartedResponseBody);
export type ApiAppointmentsGroupMonthResponse = ApiSuccessResponse & {
    data: Record<string, AppointmentsByDayRecord>;
};
export type ApiAppointmentRescheduleResponse = ApiSuccessResponse & {
    appointment: Appointment;
    reschedule_flow: AppointmentRescheduleFlow;
};
